@import "../../../assets/scss/general";

.ReportSettings {
    position: relative;

    .reportChart {

        .reportChartHeader {
            margin-bottom: 30px;
            font-size: 14px;
        }

        .chartRow {
            height: 64px;
            margin-top: 8px;

            .nameCell {
                font-family: $font-bold;
            }
        }

        .reportChartHeader, .chartRow {
            .iconCell {
                flex-basis: 60px;
                flex-grow: unset;
                padding-left: 0;

                div {
                    width: 48px;
                    height: 48px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #F5F5F5;
                    border-radius: 8px;
                    line-height: 0;
                }
            }

            .nameCell {
                flex-basis: 60%;
            }

            .thresholdCell {
                flex-basis: 20%;
            }

            .allBinsCell {
                flex-basis: 20%;
            }

            .optionsCell {
                flex-basis: 52px;
            }
        }
    }

    .formButton {
        margin-bottom: 30px;
    }
}
