@import '../../../assets/scss/general';

.SensorOverviewHeader {
    margin-bottom: 35px;
    display: flex;
    justify-content: space-between;

    .sensorOverviewStatus {
        h1 {
            font-family: $font-black;
            font-size: 20px;
            line-height: 23px;
            color: $color-dark-gray;
            margin: 0 0 8px 0;

            @include breakpoint(small) {
                font-size: 26px;
                line-height: 30px;
            }
        }

        .sortStatus {
            display: flex;
            align-items: center;
            font-family: $font-regular;
            font-size: 18px;
            line-height: 21px;

            .sortLabel {
                color: $color-silver-chalice;
            }

            .sortValue {
                font-family: $font-bold;
                color: $color-dark-gray;
            }

            .sortDirection {
                font-family: $font-medium;
                color: $color-caribbean-green;
                text-transform: capitalize;
                font-size: 18px;
                margin-left: 4px;
            }

        }
    }

    .sensorOverviewSettings {
        display: flex;
        align-items: center;
    }
}
