@import '../../../assets/scss/general';

.Slider {
  display: flex;
  height: 100%;
  user-select: none;

  .sliderWrapper {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex: 0 0 32px;

    .sliderLabel {
      display: flex;
      justify-content: center;
      font-family: $font-medium;
      font-size: 13px;
      line-height: 15px;
    }

    .sliderTrack {
      position: relative;
      margin: 12px 0;
      flex: 1;
      width: 32px;

      .sliderTrackBackground {
        width: 2px;
        height: 100%;
        border-radius: 1px;
        background: #D0D0D0;
        transform: translateX(15px);
      }

      .sliderKnobShadow {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background: radial-gradient(circle at center, rgba(0, 255, 188, 0.36) 0%, transparent 65%, transparent 100%);
        transform: translateY(-16px);

        &.readonly {
          background: none;
        }

        .sliderValueLabel {
          position: absolute;
          top: 0;
          left: 36px;
          bottom: 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          font-family: $font-black;
          font-size: 14px;
          color: $color-dark-gray;
        }
      }

      .sliderBar {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        width: 3px;
        border-radius: 3px;
        background: linear-gradient(180deg, #21A080 28%, #2AE5B5 100%);
        transform: translateX(14.5px);

        &.readonly {
          background: linear-gradient(180deg, #A0A0A0 28%, #d0d0d0 100%);
        }
      }

      .sliderKnob {
        cursor: pointer;
        position: absolute;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #21A080;
        transform: translate(12px, -4px);

        &.readonly {
          background: #A0A0A0;
        }
      }
    }
  }

  .sliderMarkers {
    flex: 0 0 16px;
    margin: 27px 0 27px 51px;
    position: relative;

    .sliderMarker {
      position: absolute;
      top: 0;
      right: 0;
      width: 8px;
      height: 2px;
      border-radius: 1px;
      overflow: hidden;
      background: #D0D0D0;
      transition: all 300ms ease-in-out;
      transition-property: width, height, border-radius;

      .sliderGradient {
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #1F9174 28.65%, #2AE5B5 100%);
        opacity: 0;
        transition: opacity 300ms linear;

        &.readonly {
          background: linear-gradient(270deg, #A0A0A0 28.65%, #d0d0d0 100%);
        }
      }

      &.marked {
        width: 16px;
        height: 3px;
        border-radius: 1.5px;

        .sliderGradient {
          opacity: 1.0;
        }
      }
    }
  }

  &.readonly {
    pointer-events: none;
  }
}