@import '../../../assets/scss/general';

.Login {
    background: url('../../../assets/img/smartbinIo-full-logo.svg') top right no-repeat;
    padding-top: 80px;

    @include breakpoint('medium') {
        padding: 60px;
        background: url('../../../assets/img/smartbinIo-full-logo.svg') top 60px right 60px no-repeat,
        url('../../../assets/img/smartbin.png') bottom right 58px no-repeat;
        background-size: auto, 180px;
    }

    .loginForm {
        width: 300px;

        .loginPanelHeadline {
            font-family: $font-black;
            font-size: 32px;
            line-height: 37px;
            text-transform: uppercase;
            color: $color-black;
            margin-bottom: 32px;
        }

        .loginPanelSubline {
            font-family: $font-medium;
            font-size: 20px;
            line-height: 23px;
            color: $color-black;
            margin-bottom: 12px;
        }

        .loginPanelText {
            font-family: $font-regular;
            font-size: 18px;
            line-height: 25.5px;
            color: $color-boulder;
            margin-bottom: 32px;
        }
    }
}
