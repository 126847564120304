@import '../../../assets/scss/general';

.UserSettings {
    .profilePictureContainer {
        position: relative;
        width: 100px;
        height: 100px;
        border-radius: 100px;

        .profilePictureUpload {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 0;
            right: 0;
            border: 1px solid transparent;
            border-radius: 24px;
            width: 24px;
            height: 24px;
            background-color: $color-black;
            transition: background-color 300ms, border-color 300ms;
            -webkit-appearance: none;

            input[type="file"] {
                display: none;
            }

            &:hover {
                cursor: pointer;
                background-color: $color-mine-shaft;
            }

            svg {
                height: 12px;
            }
        }

    }
}
