@import '../../../assets/scss/general';

/**
 * The wrapper for the verify page.
 + It contains the background image.
 */
// TODO: verify-page, verify-page, forgot-password-page should rely on share components
.Verify {
    background-size: contain;
    justify-content: center;
    display: flex;
    min-height: 100vh;
    background-size: contain;

    @include breakpoint('medium') {
        background: url('../../../assets/img/login-bg.png') top left no-repeat;
        background-size: contain;
    }
    @include breakpoint('xlarge') {
        background: url('../../../assets/img/login-bg.png') top left 25% no-repeat;
        background-size: contain;
    }

    /**
   * The panel that contains the text and the verify form.
   */
    .verifyPanel {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        // Fixed width and visible panel from iPad portrait on upwards
        @include breakpoint('medium') {
            width: 625px;
            border-radius: 12px;
            background: $color-white url('../../../assets/img/smartbin.png') bottom right 50px no-repeat;
            background-size: 120px;
            box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
        }

        // The form wrapper
        .verifyFormWrapper {
            margin: 35px;
            background: url('../../../assets/img/smartbinIo-full-logo.svg') top right no-repeat;

            @include breakpoint('medium') {
                margin: 60px;
                background: url('../../../assets/img/smartbinIo-full-logo.svg') top right no-repeat;
            }

            .verifyForm {
                width: 300px;

                .verifyPanelHeadline {
                    font-family: $font-black;
                    font-size: 32px;
                    line-height: 37px;
                    text-transform: uppercase;
                    margin-bottom: 32px;
                    color: $color-lidbot-green;
                }

                .verifyPanelSublineWrap {
                    display: flex;
                    align-items: center;
                    margin-bottom: 12px;

                    .verifyPanelSubline {
                        display: inline-block;
                        font-family: $font-medium;
                        font-size: 20px;
                        line-height: 23px;
                        color: $color-black;
                        margin-left: 10px;
                    }
                }

                .verifyPanelText {
                    font-family: $font-regular;
                    font-size: 18px;
                    line-height: 25.5px;
                    color: $color-boulder;
                    margin-bottom: 32px;
                }
            }
        }
    }
}
