@import '../../../assets/scss/general';

.SettingsNavigationLink {
    font-family: $font-regular;
    font-size: 14px;
    line-height: 16px;
    color: $color-boulder;
    cursor: pointer;
    text-decoration: none;

    &:before {
        display: block;
        content: attr(title);
        font-family: $font-bold;
        height: 0;
        overflow: hidden;
        visibility: hidden;
    }

    em {
        font-family: $font-bold;
        opacity: 0;
    }

    &.active {
        font-family: $font-bold;
        color: $color-dark-gray;

        em {
            opacity: 1;
        }
    }
}
