@import '../../../assets/scss/general';

.UserSettingsSensors {
    .sensorsSection {
        height: 45vh;
        overflow-y: scroll;
        margin-bottom: 24px;
        position: relative;
        padding: 15px 10px 15px 10px;
    }
}
