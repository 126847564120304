@import '../../../assets/scss/general';

.PasswordReset {
    display: flex;

    @include breakpoint('medium') {
        padding: 60px;
        background-size: auto;
    }

    .panelMainContent {
        width: 300px;
        margin-right: 70px;
    }

    .progressBar {
        display: flex;
        flex-direction: column;

        svg {
            margin-bottom: 65px;
        }
    }
}
