@import '../../../assets/scss/general';

.TopBinsChart {
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 12px;

  .binRow {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 8px;

    .wasteType {
      flex-basis: 50%;
      font-family: $font-medium;
      line-height: 14px;
      text-transform: capitalize;
    }
    .volume {
      flex-basis: 30%;
      font-family: $font-regular;
      color: $color-boulder;
    }
    .change {
      @extend .volume;
      flex-basis: 10%;
      padding-right: 12px;
      font-weight: bold;

      &.minus {
        color: $color-caribbean-green;
      }
      &.plus {
        color: $color-apple-blossom;
        &:before {
          content: '+';
        }
      }
    }
    .icon {

    }
  }
}
