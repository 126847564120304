@import '../../../assets/scss/general';

.FilterControls {
    ul {
        display: flex;
        margin: 0;
        padding: 0;

        > li {
            list-style: none;
            margin: 0 0 0 12px;
            font-family: $font-regular;
            font-size: 14px;
            line-height: 16px;
            color: $color-boulder;
            cursor: pointer;

            &:before {
                display: block;
                content: attr(title);
                font-family: $font-bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }

            em {
                font-family: $font-bold;
                opacity: 0;
            }

            .arrow {
                visibility: hidden;
                margin-left: 4px;
                margin-bottom: 2px;
                transform: scaleY(1);
                transition: transform 300ms linear;

                &.desc {
                    transform: scaleY(-1);
                }
            }

            &.active {
                font-family: $font-bold;
                color: $color-dark-gray;

                em {
                    opacity: 1;
                }

                .arrow {
                    visibility: visible;
                }
            }
        }
    }
}
