@import "../../../assets/scss/general";

.SensorSchedule {
  .rateSelectorWrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    min-height: calc(100vh - 520px);

    .rateSelectorTitle {
      font-family: $font-bold;
      font-size: 18px;
      line-height: 21px;
    }

    .rateSelector {
      display: flex;

      .rateValue {
        flex: 0 0 233px;
        display: flex;
        flex-direction: column;
        margin-right: 23px;

        label {
          font-family: $font-regular;
          font-size: 14px;
          color: $color-silver-chalice;
          line-height: 14px;
          margin-bottom: 15px;
        }
      }

      .rateUnit {
        flex: 0 0 212px;
        display: flex;
        flex-direction: column;

        label {
          font-family: $font-regular;
          font-size: 14px;
          color: $color-silver-chalice;
          line-height: 14px;
          margin-bottom: 15px;
        }
      }
    }
  }
}