@import '../../../assets/scss/general';

.WasteAvatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: all 300ms ease-in-out;

  &.wc-none {
    background: transparent;
    border: 1px solid $color-silver-chalice;
  }

  &.size-32 svg {
    transform: scale(0.6);
  }

  &.size-16 svg {
    display: none;
  }

  &:hover {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  }
}