@import '../../../assets/scss/general';

.SettingsNavigation {
    ul {
        display: flex;
        margin: 0;
        padding: 0;

        > li {
            list-style: none;
            margin: 0 30px 0 0;
        }
    }
}
