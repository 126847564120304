@import '../../../assets/scss/general';

.ProgressIndicatorStep {
    display: flex;
    align-items: center;

    .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-bold;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.03em;
        color: $color-white;
        background-color: $color-wild-sand;
        width: 30px;
        height: 30px;
        border-radius: 15px;
        margin-right: 7px;
        transition: 300ms ease-in-out;
    }

    .label {
        display: flex;
        flex-direction: column;

        .name {
            font-family: $font-black;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: $color-wild-sand;
            transition: 300ms ease-in-out;
        }

        .hint {
            font-family: $font-medium;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.02em;
            color: $color-wild-sand;
            transition: 300ms ease-in-out;
        }
    }

    &.active,
    &.done {
        .badge {
            color: $color-black;
            background-color: $color-gallery;
        }

        .label {
            .name {
                color: $color-black;
            }
            .hint {
                color: $color-boulder;
            }
        }
    }

    &.done {
        .stepBadge {
            background: transparent url('../../../assets/img/icons/icon.checkmark.svg') center center;
            background-size: contain;
        }
    }
}
