.Toastify__slideIn {
    animation: toastify__slideIn;
}

.Toastify__slideOut {
    animation: toastify__slideOut;
}

@keyframes toastify__slideIn {
   0% {
       transform: translateX(calc(100% + 48px));
       animation-timing-function: cubic-bezier(0.16, 0, 0.8, 1);
   }

    26% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes toastify__slideOut {
    0% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0.87, 0, 0.67, 1);
    }

    100% {
        transform: translateX(calc(100% + 48px));
    }
}