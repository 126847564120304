@import '../../../assets/scss/general';

.WasteLabel {
    &.wc-aqua {
      color: $color-wc-aqua-primary;
    }

    &.wc-green {
      color: $color-wc-green-primary;
    }

    &.wc-yellow {
      color: $color-wc-yellow-primary;
    }

    &.wc-red {
      color: $color-wc-red-primary;
    }

    &.wc-blue {
      color: $color-wc-blue-primary;
    }

    &.wc-gray {
      color: $color-wc-gray-primary;
    }

    &.wc-violet {
        color: $color-wc-violet-primary;
    }

    &.wc-organic {
        color: $color-wc-organic-primary;
    }
}