@import '../../../assets/scss/general';

.Radio {
  display: flex;
  flex-direction: column;

  label {
    margin: 0;
    padding-right: 10px;
    font-size: 14px;
    font-family: $font-regular;
    color: $color-silver-chalice;
    width: 100%;
    text-transform: capitalize;
  }

  .radioOptions {
    display: flex;
    justify-content: space-between;

    .radioOption {
      display: flex;

      label {
        display: flex;
        font-family: $font-bold;
        color: #333333;
        font-size: 15px;
        margin: 12px 0 15px 0;
      }
    }
  }

  &.options-2 .radioOptions {
    justify-content: flex-start;

    .radioOption {
      flex: 1 1 50%;
    }
  }

  &.readonly {
    pointer-events: none;
  }
}