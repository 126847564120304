@import '../../../assets/scss/general';

.StatsPanelStatus {
    display: flex;
    flex: 1 0 auto;
    justify-content: center;
    align-items: center;

    .statusValue {
        font-family: $font-bold;
        font-size: 64px;
        line-height: 75px;
        color: $color-dark-gray;
    }

    &.hasAlert {
        .statusValue {
            color: $color-roman;
        }
    }

    &.green {
        .statusValue {
            color: $color-lidbot-green;
        }
    }
}
