@import '../../../assets/scss/general';

.WasteChartTooltip {
    display: flex;
    align-items: stretch;
    background: $color-white;
    color: $color-dark-gray;
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    position: relative;

    &:after {
        top: 100%;
        left: 50%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 8px solid rgba(255, 255, 255, 0);
        border-top-color: #fff;
        margin-left: -8px;
    }

    .statusBar {
        flex: 0 0 6px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    .content {
        flex: 0 1 auto;
        padding: 15px;

        > div:first-child {
            display: flex;
            align-items: baseline;
            margin-bottom: 15px;

            .value {
                font-family: $font-bold;
                font-size: 24px;
                line-height: 19px;
                white-space: nowrap;
                margin-right: 3px;
            }

            .unit {
                font-size: 14px;
                color: #858585;
            }
        }

        > div:last-child {
            flex: 0 1 auto;
            display: flex;
            align-items: baseline;
            font-size: 10px;

            span:first-child {
                color: #858585;
                padding-right: 3px;
            }
            span:last-child {
                font-family: $font-bold;
                color: #000;
            }
        }

        > .divider {
            display: flex;
            align-items: center;
            justify-content: space-around;
            margin-bottom: 15px;
            height: 1px;

            div {
                width: 100%;
                flex-basis: 50%;
                height: 1px;

                &.left {
                    background: linear-gradient(90deg, #fff 0%, #ddd 50%, #ccc 100%);
                }
                &.right {
                    background: linear-gradient(90deg, #ccc 0%, #ddd 50%, #fff 100%);
                }
            }
        }
    }
}
