@import '../../../assets/scss/general';

$pin-button-width: 64px;
$restaurant-logo-size: 49px;

.SensorTile {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: stretch;
    border-radius: 12px;
    background: $color-white;
    box-shadow: 0 4px 8px rgba($color-dark-gray, 0.1);
    cursor: pointer;
    transition: 300ms ease-in-out;
    overflow: hidden;
    height: 100%;

    &:hover {
        transform: scale(1.05);
    }

    .imageRestaurant {
        height: $restaurant-logo-size;
        width: $restaurant-logo-size;
        object-fit: contain;
        border-radius: 100%;
        margin-top: 20px;
        margin-left: 20px;
        padding: 2px;
        border: 1px solid #e5e5e5b1;
    }

    .statusBar {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 4px;
        background: $color-white;
    }

    .sensorIdentity {
        margin: 20px 24px 0 24px;
        flex: 1 1 0;
        display: flex;
        position: relative;
        border: 0 none;
        border-bottom: 1px solid;
        border-image-source: linear-gradient(90deg, rgba(216, 216, 216, 0.2) 0%, rgba(216, 216, 216, 1) 50%, rgba(216, 216, 216, 0.2) 100%);
        border-image-slice: 1;
        padding-bottom: 12px;

        .sensorIdentityData {
            width: calc(100% - 34px);
            display: flex;
            flex-direction: column;

            .sensorName {
                flex: 1;
                display: block;
                width: 100%;
                min-height: 43px;
                font-family: $font-bold;
                font-size: 16px;
                line-height: 21px;
                color: $color-dark-gray;
                text-transform: uppercase;
                word-break: break-all;
                overflow: hidden;
                text-overflow: clip;
                margin-bottom: 5px;
            }

            .sensorType {
                display: flex;
                align-items: center;
                height: 16px;
                margin-top: 2px;

                .sensorTypeName {
                    font-family: $font-medium;
                    font-size: 14px;
                    line-height: 16px;
                    color: $color-caribbean-green;
                    text-transform: uppercase;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }

        .sensorPinButton {
            display: flex;
            position: absolute;
            top: -9px;
            right: -24px;
            width: 64px;
            align-items: center;
            justify-content: center;
            height: 39px;
            border: 0 none;
            padding: 0;
            background: transparent;
            cursor: pointer;
            -webkit-appearance: none;

            svg {
                fill: $color-alto;
            }

            &:hover svg {
                fill: $color-dark-gray;
            }
        }
    }

    .sensorLocation {
        flex: 1 1 auto;
        margin: 5px 24px 15px 24px;
        display: flex;
        align-items: stretch;
        justify-content: space-between;

        .sensorFillStatus {
            flex: 0 1 auto;
            position: relative;
            font-family: $font-medium;
            font-size: 36px;
            line-height: 42px;
            color: $color-dark-gray;
            padding-right: 20px;
            margin-right: 20px;
            min-height: 42px;

            .sensorFillStatusUnit {
                position: absolute;
                top: 0;
                right: 0;
                font-family: $font-medium;
                font-size: 26px;
                line-height: 30px;
            }
        }

        .sensorAddress {
            flex: 1;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            svg {
                margin-left: 10px;
            }

            .sensorAddressTitle {
                font-family: $font-bold;
                font-size: 18px;
                line-height: 21px;
                text-transform: uppercase;
                color: $color-dark-gray;
            }

            .sensorAddressDetails {
                font-family: $font-regular;
                font-size: 12px;
                line-height: 14px;
                color: $color-silver-chalice;
            }
        }
    }

    //&.isConnected .sensorIdentity .sensorIdentityData .sensorType:before {
    //    content: '';
    //    display: block;
    //    width: 6px;
    //    height: 6px;
    //    margin-right: 8px;
    //    border-radius: 8px;
    //    background-color: $color-caribbean-green;
    //}

    //&.notResponding {
    //    border-color: $color-apple-blossom;
    //    border-width: 2px;
    //    border-style: dashed;
    //}

    &.hasAlert {
        .statusBar {
            background: $color-apple-blossom;
            background: linear-gradient(180deg, rgba($color-apple-blossom, 1) 0%, rgba($color-roman, 0.5) 100%);
        }

        .sensorIdentity {
            .sensorIdentityData {
                .sensorType {
                    //&:before {
                    //    background-color: $color-apple-blossom;
                    //}

                    .sensorTypeName {
                        color: $color-apple-blossom;
                    }
                }
            }
        }

        .sensorLocation {
            .sensorFillStatus {
                color: $color-roman;

                .sensorFillStatusUnit {
                    color: $color-roman;
                }
            }
        }
    }

    &.isPinned {
        .sensorPinButton svg {
            fill: $color-dark-gray;
        }
    }
}
