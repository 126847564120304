@import '../../../assets/scss/general';

.CustomersSettings {
    .content {
        min-height: calc(100vh - 314px);
        margin-bottom: 70px;

        .header {
            height: 12px;
            font-size: 12px;
            margin-bottom: 24px;
        }

        .row {
            height: 44px;
            color: $color-dark-gray;
            margin-top: 8px;
        }

        .header, .row {
            margin-left: -64px;

            .iconCell {
                flex-basis: 36px;
                padding-left: 7px;

                .clientIndicator {
                    width: 8px;
                    height: 8px;
                    background: $color-lidbot-green;
                    border-radius: 50%;
                    margin-left: 6px;

                }
            }

            .nameCell, .timezoneCell {
                flex: 1 1 0;
            }

            .languageCell {
                flex-basis: 112px;
            }

            .accountOptionsCell {
                flex-basis: 105px;
            }

            .optionsCell {
                flex-basis: 52px;
            }
        }

        .row .nameCell {
            .nameLabel {
                font-family: $font-bold;
                margin-bottom: 4px;
            }

            .idLabel {
                font-size: 12px;
                color: $color-silver-chalice;
                text-transform: none;
            }
        }

        .row .accountOptionsCell {
            justify-content: center;

            svg {
                fill: $color-alto;
            }

            .enabled {
                svg {
                    fill: $color-dark-gray;
                }
            }
        }
    }

    .controls {
        margin-top: 24px;
        margin-bottom: 15px;
    }
}