@import '../../../assets/scss/general';

.FloatingPanel {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $color-white;
    box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
    width: 100%;
    height: 100vh;

    @include breakpoint('medium') {
        display: block;
        padding: 60px;
        width: 625px;
        height: auto;
        border-radius: 12px;
        box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);

        &.collapsed {
            padding: 0;
        }
    }
}
