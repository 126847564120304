@import '../../../assets/scss/general';


.DashboardView {
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    .dashboardMainStage {
        flex: 1 1 auto;
        display: flex;
        overflow: hidden;

        .dashboardNavigation {
            overflow-y: scroll;
        }

        .dashboardContent {
            background: linear-gradient(to top right, rgba(232, 232, 232, 0.44) 0.1%, rgba(255, 255, 255, 0) 57.84%);
            flex: 1 1 auto;
            overflow-y: scroll;
            padding: 0 40px 40px 40px;
        }
    }
}
