@import "../../../assets/scss/general";

.Row {
  display: flex;
  position: relative;
  align-items: stretch;
  font-size: 14px;
  color: $color-dark-gray;

  > * {
    opacity: 1.0;
    transition: opacity 300ms linear;
  }

  &.header {
    font-size: 14px;
    color: $color-silver-chalice;
  }

  &.selected, &:hover {
    .highlighted {
      background: $color-white;
      box-shadow: 0 2px 8px rgba($color-dark-gray, 0.1);
      border-radius: 12px;
    }

    .options {
      .optionsIcon {
        visibility: visible;
      }
    }
  }

  &.selected {
    > * {
      opacity: 0.5;
      transition: opacity 300ms linear;
    }

    .options {
      opacity: 1.0;

      .optionsIcon svg {
        fill: $color-lidbot-green;

        path {
          fill: $color-lidbot-green;
        }
      }
    }
  }

  &.selected .options .optionsIcon .optionsFlyover {
    visibility: visible;
  }

  &.clickable {
    cursor: pointer;
  }
}

.RowCell {
  display: block;
  align-items: center;
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 28px;
  box-sizing: border-box;
  overflow: hidden;

  &.icon {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0;
  }

  &.highlighted, &.grouped {
    display: flex;
    position: relative;
    align-items: center;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0;
  }

  &.options {
    display: flex;
    overflow: visible;
    justify-content: flex-end;
    padding: 0 20px 0 16px;
    margin-right: -20px;

    .optionsIcon {
      height: 38px;
      position: relative;
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      .optionsFlyover {
        top: 0;
        position: absolute;
        visibility: hidden;
        background: $color-gray-99;
        box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
        border-radius: 8px;
        right: 32px;
        z-index: 1;
      }
    }
  }

  &.flexible {
    flex-grow: 1;
    flex-shrink: 1;
  }

  &.static {
    flex-grow: 0;
    flex-shrink: 0;
  }

  &.bordered {
    border-right: 1px solid rgba($color-alto, 0.75);
  }

  &.header {
    overflow: visible;
  }

  &.clickable {
    cursor: pointer;
  }
}