@import '../../../assets/scss/general';

.UserSettingsModal {
  .steps {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    margin-top: 25px;
    margin-bottom: 25px;
    color: #757575;

    ul {
      list-style-type: none;
      margin: 0 0 0 -10px;
      padding: 0;
      overflow: hidden;
    }

    li {
      float: left;
      display: flex;
      padding: 5px 10px;
      border-radius: 10px;
      transition: background-color 300ms;

      &:hover {
        cursor: pointer;
        color: #333;
      }

      &.selected {
        font-weight: 700;
        color: #000;

        &:after {
          content: ".";
          color: $color-caribbean-green;
        }
      }
    }
  }
}
