@import '../../../assets/scss/general';

.FullBinsChart {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 0 auto;

    .fullBinsChartValue {
        z-index: 1;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        font-family: $font-bold;
        font-size: 28px;
        line-height: 33px;
        opacity: 0.7;
        color: $color-dark-gray;
    }
}
