$color-roman: #DA6464; //218, 100, 100
$color-apple-blossom: #a74040; //167, 64, 64
$color-caribbean-green: #00C391; // #00BF8D ?
$color-lidbot-green: #46b490; //70, 180, 144
$color-active-green: #00CD98;
$color-sinbad: #A2D9C7; //162, 217, 199
$color-white: #fff;
$color-black: #000;
$color-mine-shaft: #3A3A3A; //58, 58, 58
$color-dark-gray: #333; //51, 51, 51
$color-silver-chalice: #A0A0A0; //160, 160, 160
$color-boulder: #757575; //117, 117, 117
$color-wild-sand: #F5F5F5; //245, 245, 245
$color-alabaster: #FAFAFA; //250, 250, 250
$color-alto: #D8D8D8; //216, 216, 216
$color-gray: #929292; //146, 146, 146
$color-gallery: #ECECEC; //236, 236, 236
$color-silver: #C6C6C6; //198, 198, 198
$color-anzac: #DDA045; //221, 160, 69
$color-gray-99: #FCFCFC; //252, 252, 252
$color-dark-green: #003B2C; //0, 59, 44
$color-opal: #A7CFC5; // 167, 207, 197
$color-wc-aqua-primary: #46B490;
$color-wc-aqua-secondary: #E3F4EE;
$color-wc-green-primary: #4FAE49;
$color-wc-green-secondary: #E5F3E4;
$color-wc-yellow-primary: #FAB635;
$color-wc-yellow-secondary: #FEF4E1;
$color-wc-red-primary: #FC4939;
$color-wc-red-secondary: #FFE5E2;
$color-wc-blue-primary: #286DCC;
$color-wc-blue-secondary: #D5E2F5;
$color-wc-gray-primary: #37383A;
$color-wc-gray-secondary: rgba(55, 56, 58, 0.2);
$color-wc-organic-primary: #FF8A00;
$color-wc-organic-secondary: #FFEDD9;
$color-wc-violet-primary: #6628CC;
$color-wc-violet-secondary: #E0D4F5;


