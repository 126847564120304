@import '../../../assets/scss/general';

.RadioInput {
  display: inline-flex;

  input[type="radio"] {
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0;
    width: 0;
    height: 0;
  }

  input:focus + .RadioButton {
    .border {
      border-color: $color-lidbot-green;
    }
  }
}

.RadioButton {
  margin-right: 16px;

  .border {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 16px;
    height: 16px;
    border: 1px solid #EAEAEA;
    border-radius: 50%;

    .dot {
      width: 8px;
      height: 8px;
      border-radius: 50%;
    }
  }

  &.checked {
    .dot {
      background: $color-lidbot-green;
    }
  }
}