@import '../../../assets/scss/general';

.ChartTooltip {
    display: flex;
    align-items: stretch;
    background: $color-white;
    color: $color-dark-gray;
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    transform: translateX(-50%) translateY(calc(-100% - 15px));

    &:after {
        top: 100%;
        left: 50%;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: 8px solid rgba(255, 255, 255, 0);
        border-top-color: #fff;
        margin-left: -8px;
    }

    .statusBar {
        flex: 0 0 4px;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        background: linear-gradient(135deg, rgba(0, 195, 145, 1) 0%, rgba(23, 202, 155, 0.3) 100%);
    }

    .sensorTooltipContent {
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        padding: 15px 15px 15px 0;

        .sensorFillStatus {
            font-family: $font-bold;
            font-size: 16px;
            line-height: 19px;
            white-space: nowrap;
            margin: 0 10px;
        }

        .sensorDetails {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            .sensorName {
                font-family: $font-bold;
                font-size: 13px;
                line-height: 15px;
                white-space: nowrap;
            }

            .sensorStatus {
                font-family: $font-regular;
                font-size: 10px;
                line-height: 12px;
                white-space: nowrap;
            }
        }
    }

    &.hasAlert {
        // The status bar on the right
        .statusBar {
            background: $color-apple-blossom;
            background: linear-gradient(180deg, rgba($color-apple-blossom, 1) 0%, rgba($color-roman, 0.5) 100%);
        }
        
        .sensorTooltipContent .sensorFillStatus {
            color: $color-apple-blossom;
        }
    }
}
