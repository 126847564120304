@import '../../../assets/scss/_general.scss';

.UserRow {
    height: 40px;
    margin-bottom: 20px;

    .iconCell {
        flex-basis: 48px;
    }

    .nameCell {
        flex-basis: 30%;
        font-family: $font-bold;
    }

    .emailCell {
        flex-basis: 40%;
        font-family: $font-regular;
    }

    .roleCell {
        width: 20%;
        font-family: $font-medium;
        text-transform: capitalize;
    }

    .sensorsCell {
        flex-basis: 10%;
        font-family: $font-bold;
    }

    .statusCell {
        flex-basis: 200px;
        font-family: $font-bold;
        text-transform: capitalize;

        &.pending {
            color: $color-anzac;
        }

        &.confirmed {
            color: $color-lidbot-green;
        }

        &.disabled {
            color: $color-black;
        }
    }

    .optionsCell {
        flex-basis: 52px;
    }

    .flyover {
        width: 145px;
    }
}
