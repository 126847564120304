@import '../../../assets/scss/general';

$box-shadow: 0 0 10px rgba($color-dark-gray, 0.25);
$padding: 24px;

.NotificationCenter {
  width: 400px;

  &:after {
    position: absolute;
    bottom: 0;
    height: 80px;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
            rgba(255,255,255, 1) 0%,
            rgba(255,255,255, 0) 100%
    );
    pointer-events: none; /* so the text is still selectable */
    border-radius: 0 0 8px 8px;
  }

  .__arrow {
    width: 50px;
    height: 25px;
    position: absolute;
    top: -25px;
    left: 190px;
    transform: rotate(180deg);
    overflow: hidden;

    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 20px;
      background: #FCFCFC;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      top: 0;
      left: 50%;
      box-shadow: $box-shadow;
    }
  }

  .message {
    font-family: "Roboto-Bold", "Roboto", "Segoe UI", Arial, sans-serif;
    font-size: 12px;
    text-align: center;
    line-height: 14px;
    color: #000;
    opacity: .2;
    padding: 0 20px;
    margin: 0;
  }

  .head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h1 {
      font-family: $font-black;
      font-size: 26px;
      margin: 0;
      &:after {
        content: ".";
        color: $color-caribbean-green;
      }
    }

    > div {
      color: $color-caribbean-green;
      font-family: $font-bold;
      font-size: 10px;
      line-height: 12px;
      padding: 5px 0 5px 5px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .content {
    overflow-y: scroll;
    height: calc(80vh - 66px);

    .section {
      margin-bottom: 50px;

      h2 {
        font-family: $font-bold;
        margin: 0 0 10px 0;
        font-size: 13px;
        line-height: 14px;
        text-transform: capitalize;
        padding: 0 $padding;
      }
      &.old {
        opacity: .6;
      }
    }

    .divider {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 40px;

      div {
        width: 100%;
        flex-basis: 30%;
        height: 1px;

        &.left {
          background: linear-gradient(90deg, #fff 0%, #ddd 50%, #ccc 100%);
        }
        &.right {
          background: linear-gradient(90deg, #ccc 0%, #ddd 50%, #fff 100%);
        }
      }

      h2 {
        flex-basis: 56%;
        font-family: $font-bold;
        font-size: 14px;
        text-align: center;
        line-height: 14px;
        color: #000;
        opacity: .2;
        padding: 0 20px;
        margin: 0;
        text-transform: capitalize;
      }
    }
  }
}
