@import '../../../assets/scss/general';

$color-dot-color: $color-caribbean-green;
$color-adjusted-background-color: #C9E8E0;
$color-selected-background-color: #def9f2;

.Calendar {
  user-select: none;

  .header {
    display: flex;
    align-items: stretch;
    height: 30px;
    margin-bottom: 30px;
    font-size: 12px;

    .previous, .next, .month, .year {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 43px;
      background: #F7F7F7;
      border: 1px solid #E8E8E8;
      border-radius: 4px;
      padding: 0 9px;
      font-family: $font-medium;

      &.disable {
        visibility: hidden;
      }
    }

    .next {
      transform: scaleX(-1);
    }

    .date {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $color-boulder;
    }

    &.highlighted {
      justify-content: space-between;
    }
  }

  .grid {
    position: relative;
    width: 245px;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-auto-rows: 24px;

    .weekday {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-family: $font-black;
    }

    .date {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      cursor: pointer;

      .dateInnerLayer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;

        &.flexCenter {
          justify-content: center;
          align-items: center;
        }
      }

      .adjustedBackground, .selectedBackground {
        display: flex;
        flex-basis: 100%;

        .left, .right {
          flex-basis: 50%;
          background: transparent;
          transition: background 300ms linear;
        }
      }


      .selectedDot, .adjustedDot {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background: transparent;
        transition: background 300ms linear;
      }

      .dateContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-medium;
        color: $color-black;
        transition: color 100ms linear;
      }

      &.selected {
        .selectedBackground {
          .right, .left {
            background: $color-selected-background-color;
          }
        }

        &.selected-start, &.selected-end {
          .selectedDot {
            background: $color-selected-background-color;
          }
        }

        &.selected-start .selectedBackground .left,
        &.selected-end .selectedBackground .right {
          background: none;
        }
      }

      &.adjusted {
        .adjustedBackground {
          .right, .left {
            background: $color-adjusted-background-color;
          }
        }

        &.adjusted-start, &.adjusted-end {
          .adjustedDot {
            background: $color-dot-color;
          }

          .dateContainer {
            color: $color-white;
          }
        }

        &.adjusted-start .adjustedBackground .left,
        &.adjusted-end .adjustedBackground .right {
          background: transparent;
        }
      }

      &.out-of-month .dateContainer {
        color: $color-boulder;
      }

      &.out-of-range {
        opacity: 0.3;
        pointer-events: none;
      }

      &.today .dateContainer {
        color: $color-caribbean-green;
      }
    }

    &:hover {
      .selected-start .selectedDot {
        background-color: $color-selected-background-color;
      }

      .adjusted-start .adjustedDot {
        background-color: $color-dot-color;
      }
    }
  }
}