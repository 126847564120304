@import '../../../assets/scss/general';

.SidebarLink {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    text-decoration: none;

    &:first-child {
        padding-top: 3px;
    }

    svg {
        fill: #333;
    }

    &.disabled {
        pointer-events: none;

        svg {
            fill: $color-alto;
        }
    }

    .label {
        visibility: hidden;
        height: 0;
        opacity: 0;
        margin: 0;
        font-family: $font-bold;
        font-size: 14px;
        line-height: 16px;
        color: $color-dark-gray;
        text-decoration: none;
        transform: translateY(-100%) scaleY(0);
        transition: all 300ms ease-in-out;
    }

    &.active {
        svg {
            fill: url('#active-gradient');
        }
    }

    &.active,
    &:hover {
        .label {
            visibility: visible;
            opacity: 1;
            margin: 10px 0 15px 0;
            height: auto;
            transform: translateY(0) scaleY(1);
        }

        &:after {
            content: '';
            display: block;
            width: 24px;
            height: 2px;
            background: linear-gradient(269.86deg, #00c391 0.09%, rgba(0, 115, 85, 0.7) 98.33%);
        }
    }

    &:visited {
        text-decoration: none;
    }
}
