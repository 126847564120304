@import '../../../assets/scss/general';

.LoadingBar {
  position: fixed;
  top: 0;
  left: 0;
  height: 6px;
  width: 100%;
  z-index: 2000;

  .bar {
    position: absolute;
    height: 100%;
    width: 200%;
    left: -200%;
    background: linear-gradient(90deg, #00C391 0%, #00C391 50%, rgba(0, 195, 145, 0) 100%);
    transition-property: transform;
  }

  &.loading {

    .bar {
      transform: translateX(60%);
      transition-duration: 5s;
      transition-timing-function: cubic-bezier(0, 1, 0.6, 1);
    }

    &.done  {
      .bar {
        transform: translateX(100%);
        transition-duration: 300ms;
        transition-timing-function: ease-out;
        animation: fadeOut 300ms 300ms forwards;
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}