@font-face {
    font-family: 'Roboto-Light';
    src: url('../../fonts/Roboto/roboto-v20-latin-300.woff2');
    font-style: normal;
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto-Regular';
    src: url('../../fonts/Roboto/roboto-v20-latin-regular.woff2');
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto-Medium';
    src: url('../../fonts/Roboto/roboto-v20-latin-500.woff2');
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto-Bold';
    src: url('../../fonts/Roboto/roboto-v20-latin-700.woff2');
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto-Black';
    src: url('../../fonts/Roboto/roboto-v20-latin-900.woff2');
    font-style: normal;
    font-weight: 900;
}

// Fallback font
@font-face {
    font-family: 'Segoe UI';
    font-weight: 400;
    src: local('Segoe UI');
}
