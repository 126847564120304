@import '../../../assets/scss/general';

.TopBinsChart {
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .binRow {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 8px;

    .binTitle {
      flex: 0 0 102px;
      font-family: $font-medium;
      font-size: 12px;
      line-height: 14px;
      color: $color-boulder;
      padding-right: 12px;
      text-transform: capitalize;
    }

    .binBarChart {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      > div {
        height: 2px;
        border-radius: 4px;
        background: linear-gradient(90deg, $color-opal 0%, $color-dark-green 100%);
      }
    }

    .percentage {
      flex: 0 0 35px;
      display: flex;
      justify-content: flex-end;
      font-family: $font-regular;
      font-size: 12px;
      color: $color-boulder;
    }

    &.hasAlert {
      .binBarChart {
        > div {
          background: linear-gradient(90deg, rgba($color-roman, 0.5) 0%, $color-roman 100%);
        }
      }

      .percentage {
        color: $color-roman;
        font-family: $font-medium;
      }
    }
  }
}
