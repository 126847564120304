@import '../../../assets/scss/general';

.ViewSwitcher {
    display: flex;
    margin-left: 15px;

    button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        margin: 0 0 0 25px;
        border: 0 none;
        border-radius: 12px;
        padding: 0;
        background: $color-white;
        -webkit-appearance: none;
        box-shadow: 0 4px 8px rgba(51, 51, 51, 0.15);
        cursor: pointer;

        svg {
            width: 16px;
            height: 16px;
            fill: $color-black;
        }

        &.activeDisplayOption {
            background: $color-black;

            svg {
                fill: $color-white;
            }
        }
    }
}
