@import '../../../assets/scss/general';

$animation-time: 300ms;

.AnimatedSortedListContainer {
  position: relative;

  .hiddenContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    z-index: -1;
  }

  .visibleContainer {
    position: relative;
    visibility: visible;
    z-index: 1;
  }

  .fadeOutContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    > * {
      position: absolute;
    }
  }

  &.animating {
    .visibleContainer {
      .fadeIn {
        animation: fadeIn $animation-time forwards;
      }

      .translate {
        animation: translate $animation-time forwards;
      }
    }

    .fadeOutContainer {
      .fadeOut {
        animation: fadeOut $animation-time forwards;
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1.0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1.0;
  }
  100% {
    opacity: 0.0;
  }
}

@keyframes translate {
  100% {
    transform: translate(0,0);
  }
}