@import '../../../assets/scss/general';

.DashboardHeader {
    flex: 0 0 auto;
    display: flex;
    align-items: center;

    padding:  20px 40px 20px 20px;

    .logo {
        svg {
            width: auto;
            height: 20px;

            @include breakpoint(small) {
                height: auto;
            }
        }
    }
}
