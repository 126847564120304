@import '../../../assets/scss/general';

.StatsPanel {
    border-radius: 12px;
    padding: 25px;
    background: $color-white;
    box-shadow: 0 4px 8px rgba($color-dark-gray, 0.2);
    overflow: hidden;

    .panelInner {
        display: flex;
        flex-direction: column;
        height: 100%;

        .panelHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                font-family: $font-bold;
                font-size: 18px;
                line-height: 21px;
                color: $color-dark-gray;
            }

            .enlarge {
                display: flex;
                justify-content: center;
                align-items: center;
                -webkit-appearance: none;
                border: 0 none;
                background-color: transparent;
                cursor: pointer;
            }
        }
    }
}
