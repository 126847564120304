@import '../../../assets/scss/general';

.Sidebar {
    display: flex;
    flex-direction: column;
    background-color: $color-white;

    flex-basis: 90px;
    min-width: 90px;

    @include breakpoint(large) {
        flex-basis: 100px;
        min-width: 100px;
    }

    @include breakpoint(xlarge) {
        flex-basis: 120px;
        min-width: 120px;
    }

    hr {
        width: 48px;
        margin: 15px auto;
        border: 0 none;
        border-bottom: 1px solid rgba($color-alto, 0.75);
    }

    .chatButton {
        background-color: $color-white;
        cursor: pointer;
        border: 0 none;
        outline: none;
        padding: 15px;
        margin: auto auto 20px auto;
        border-radius: 12px;
        box-shadow: 0px 4px 16px rgba(8, 81, 79, 0.29);
        transition: 300ms ease-in-out;

        &:hover {
            background-color: #000;
        }
    }

    .sidebarLogoutButton {
        -webkit-appearance: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: auto;
        border: 0 none;
        font-family: $font-bold;
        font-size: 14px;
        line-height: 16px;
        color: $color-dark-gray;
        background-color: $color-white;
        cursor: pointer;
        transition: 300ms ease-in-out;
        padding-top: 16px;

        svg {
            margin-top: 10px;
        }

        &:hover {
            background: $color-black;
            color: $color-white;
        }
    }

    .sidebarTilesIcon {
        fill: $color-dark-gray;

        &:hover {
            fill: url(#icon-tiles-gradient);
        }
    }

    .sidebarSensorIcon {
        fill: $color-dark-gray;

        &:hover {
            fill: url(#icon-sensor-gradient);
        }
    }
}
