@import '../../../assets/scss/general';

.Fader {
  position: relative;

  .child {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 300ms;

    &.visible {
      opacity: 1;
    }
  }
}