@import '../../../assets/scss/general';

$iconSize: 22px;

.Help {
  svg {
    width: $iconSize;
    height: $iconSize;

    path {
      transition: fill 200ms ease-in-out;
    }

    &:hover {
      path {
        fill: #00C391;
      }
    }
  }
}