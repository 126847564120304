@import '../../../assets/scss/general';

$transition-timing: 460ms cubic-bezier(0.53, 0, 0, 1);

.AnimatedOptionLabel {
  position: relative;
  display: flex;
  align-items: stretch;

  .previousLabel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    z-index: 10;
    opacity: 0;
  }

  .currentLabel {
    flex: 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 11;
  }

  .icon {
    margin: 0 10px 0 -10px;
  }

  &.transitioning {
    .previousLabel {
      animation: transition_out $transition-timing;
    }

    &.up .currentLabel {
      animation: transition_in_up $transition-timing;
    }

    &.down .currentLabel {
      animation: transition_in_down $transition-timing;
    }
  }
}

@keyframes transition_out {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 0;
  }
}

@keyframes transition_in_up {
  0% {
    opacity: 0;
    transform: translateY(75%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes transition_in_down {
  0% {
    opacity: 0;
    transform: translateY(-75%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}