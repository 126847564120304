.SensorDetails {
    .modalContent {
        margin: 25px 0;
    }

    .modalButtonContainer {
        display: flex;
        justify-content: flex-end;

        > button {
            margin-left: 20px;
        }
    }
}
