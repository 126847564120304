@import '../../../assets/scss/general';

$padding: 25px;
$icon-width: 33px;
$icon-small-width: 10px;

.NotificationRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 12.5px 2.5px 12.5px;
  padding: 12.5px;

  &.read {
    background: red;
  }

  > * {
    opacity: 1;
    transition: all 300ms linear;
  }

  &.selected, &:hover {
    background: $color-white;
    box-shadow: 0 2px 8px rgba($color-dark-gray, 0.1);
    border-radius: 12px;

    .options {
      opacity: 1;
      display: flex;
    }
    .time {
      opacity: 0;
      display: none;
    }
  }

  .icon {
    flex: 0 1 24px;

    svg {
      width: $icon-width;
      height: $icon-width;
    }
  }

  .col1 {
    flex-basis: calc(100% - 24px - 40px);
    padding-left: 20px;
  }
  .time, .options {
    flex: 0 1 50px;
  }
  .time {
    font-family: $font-bold;
    font-size: 10px;
    color: #000;
    opacity: .35;

    > div {
      text-align: right;
    }
  }
  .options {
    display: none;
    opacity: 0;
    justify-content: flex-end;
    align-items: center;

    > svg {
      cursor: pointer;
      margin: 5px;
      width: $icon-small-width;
      height: $icon-small-width;
    }
  }

  h3 {
    margin: 0;
    font-family: $font-bold;
    font-size: 12px;
    line-height: 1.1;
  }

  span {
    font-family: $font-medium;
    font-size: 12px;
    line-height: 12px;
    color: #6D6D6D;

    b {
      @extend span;
    }
  }
}