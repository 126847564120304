@import '../../../assets/scss/general';

.UsersSettings {

  .deleteModalIconWrapper {
    position: relative;

    .icon {
      width: 100%;
      height: 100%;
    }

    .symbol {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      box-sizing: border-box;
      right: -3px;
      bottom: 0;
      background: $color-roman;
      border: 1px solid $color-white;

      div {
        background: $color-white;
        height: 2px;
        width: 4px;
      }
    }
  }
}

.UsersTableHeader {
  display: flex;
  position: relative;
  font-family: $font-regular;
  font-size: 14px;
  align-items: center;
  color: $color-silver-chalice;
  margin-bottom: 15px;

  .headerCell {
    display: flex;
    align-items: center;
    height: 12px;
    box-sizing: border-box;
  }

  .profilePicCell {
    flex: 0 0 48px;
    padding-right: 16px;
    justify-content: center;
    border-right: 1px solid rgba($color-alto, 0.75);
  }

  .flexibleCells {
    flex: 1;
    display: flex;

    .nameCell {
      width: 30%;
      padding: 0 28px;
      border-right: 1px solid rgba($color-alto, 0.75);
    }

    .emailCell {
      width: 40%;
      padding: 0 28px;
      border-right: 1px solid rgba($color-alto, 0.75);
    }

    .roleCell {
      width: 20%;
      padding: 0 28px;
      border-right: 1px solid rgba($color-alto, 0.75);
    }

    .sensorsCell {
      width: 10%;
      padding: 0 28px;
    }
  }

  .statusCell {
    flex: 0 0 200px;
    padding: 0 28px;
  }

  .optionsCell {
    flex: 0 0 36px;
  }
}