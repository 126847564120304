@import '../../../assets/scss/general';

.PasswordResetForm {
    .headline {
        font-family: $font-black;
        font-size: 32px;
        line-height: 37px;
        text-transform: uppercase;
        color: $color-black;
        margin-bottom: 32px;
    }

    .subline {
        font-family: $font-bold;
        font-size: 20px;
        line-height: 23px;
        color: $color-lidbot-green;
        margin-bottom: 12px;
    }

    .text {
        font-family: $font-regular;
        font-size: 16px;
        line-height: 25.5px;
        color: $color-boulder;
        margin-bottom: 32px;
    }

}
