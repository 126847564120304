@import '../../../assets/scss/general';

.PublicPageFrame {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-size: contain;

    @include breakpoint('medium') {
        background-image: url('../../../assets/img/login-bg.png');
        background-repeat: no-repeat;
        background-position-x: calc(50% - 312.5px);
    }
}
