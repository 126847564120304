@import '../../../assets/scss/general';

.FormLabel {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  font-size: 14px;
  font-family: $font-regular;
  color: $color-silver-chalice;
  width: 100%;

  &.capitalize {
    text-transform: capitalize;
  }

  &.required:after {
    content: '*';
    padding-left: 3px;
    color: $color-lidbot-green;
  }
}