@import '../../../assets/scss/general';

.NotificationToast {
    border-radius: 8px;
    padding: 20px 25px;

    .notificationHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-family: $font-black;
            font-size: 18px;
            line-height: 21px;
            color: $color-black;
        }

        .icon {
            flex: 0 0 auto;
            margin-left: 25px;
            width: 40px;
            height: 40px;
            border-radius: 20px;
        }
    }

    .notificationContent {
        color: $color-black;
        margin-top: 20px;
        border-top: 1px solid black;
        padding-top: 20px;
        font-family: $font-regular;
        font-size: 12px;
        line-height: 17px;
        border-image-slice: 1;
    }

    &.success {
        background-color: #CFF4EB;

        .notificationHeader {
            .icon {
                background: #46B490 url('../../../assets/img/icons/icon.notification-success.svg') center center no-repeat;
            }
        }

        .notificationContent {
            border-image-source: linear-gradient(90deg, #CFF4EB 0, $color-caribbean-green 50%, #CFF4EB 100%);
        }
    }

    &.warning {
        background-color: #FFEBCD;

        .notificationHeader {
            .icon {
                background: #FFBD5B url('../../../assets/img/icons/icon.notification-warning.svg') center center no-repeat;
            }
        }

        .notificationContent {
            border-image-source: linear-gradient(90deg, #FFEBCD 0, #FFBD5B 50%, #FFEBCD 100%);
        }
    }

    &.error {
        background-color: #F4CFCF;

        .notificationHeader {
            .icon {
                background: #DA6464 url('../../../assets/img/icons/icon.notification-error.svg') center center no-repeat;
            }
        }

        .notificationContent {
            border-image-source: linear-gradient(90deg, #F4CFCF 0, #DA6464 50%, #F4CFCF 100%);
        }
    }
}
