@import '../../../assets/scss/general';

.ReportSettingsGeneral {
  display: flex;
  flex-direction: column;

  .inputs {
    display: flex;
    justify-content: space-between;
    flex: 1;

    .inputsColumn {
      width: 50%;

      .switch {
        margin-bottom: 50px;
      }

      .slider {
        opacity: 1;
        transition: all 300ms ease-in-out;

        &.readonly {
          opacity: .5;
        }

        > div {
          &:first-child {
            margin-bottom: .5em;
          }
        }
      }

      label {
        font-size: 12px;
        color: #A0A0A0;
        margin-bottom: 1em;

        b {
          color: #666;
        }

        &.primary {
          font-size: 14px;
        }
      }

      .typeSection {
        label {
          font-size: 14px;
          margin-bottom: 20px;
        }
      }

      .typeGrid {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(64px, 64px));
        grid-column-gap: 20px;
        grid-row-gap: 20px;
      }

      .typeIcon {
        cursor: pointer;
        text-align: center;

        label {
          display: block;
          font-weight: bold;
        }

        &.selected {
          label {
            color: #00C391;
          }

          svg {
            background-color: #00C391;

            path {
              fill: #fff;
            }
          }

          &:hover {
            svg {
              path {
                fill: #fff;
              }
            }
          }
        }

        &:hover {
          svg {
            path {
              fill: #00C391;
            }
          }
        }

        svg {
          padding: 20px;
          background-color: #F5F5F5;
          border-radius: 8px;
          width: 64px;
          height: 64px;
          margin-bottom: 5px;

          path {
            fill: #757575
          }
        }
      }

      &:first-child {
        padding-right: 50px;
      }

      &.wasteType {
        background: url('../../../assets/img/waste-types.png') top left no-repeat;
        background-size: 100%;
        background-position: right bottom;
        margin-right: -24px;
        margin-bottom: -24px;

        div {
          margin-left: -24px;

          &:first-child {
            margin-bottom: .5em;
          }

          &:nth-child(2) {
            width: 80%;
          }
        }
      }
    }
  }
}

.thresholdSettings {
  flex: 0 0 315px;

  .thresholdTitle {
    font-family: $font-bold;
    font-size: 18px;
    line-height: 21px;
    color: $color-dark-gray;
    margin-bottom: 8px;
  }

  .thresholdSubtitle {
    font-family: $font-regular;
    font-size: 12px;
    line-height: 130%;
    color: $color-silver-chalice;
    width: 310px;

    b {
      font-weight: $font-medium;
      color: $color-dark-gray;
    }
  }

  .thresholdSliderWrapper {
    margin-top: 30px;
    display: flex;
    align-items: stretch;
    height: 235px;

    .thresholdSlider {
      flex: 0 0 100px;
    }

    .thresholdImage {
      margin-left: 30px;
      flex: 0 0 84px;
      background: url('../../../assets/img/lidbot-bin-uncropped.png') no-repeat left center/contain;
    }
  }
}
