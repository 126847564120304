@import "../../../assets/scss/general";

.TimeCell {
  border-bottom: 1px solid rgba($color-alto, 0.75);

  &:last-child {
    border-bottom: none;
  }

  &.weekend {
    background: #FCFCFC;
    border-bottom: 1px dashed rgba($color-alto, 0.75);
  }

  .card {
    position: relative;
    display: flex;
    visibility: hidden;
    background: transparent;
    opacity: 0;
    border-width: 0;
    border-style: solid;
    border-color: transparent;
    box-sizing: border-box;
    box-shadow: none;
    min-width: 50px;
    width: 130px;
    margin: 0 auto;
    border-radius: 10px;
    top: 10px;
    height: 30px;
    overflow: hidden;
    transition: 300ms ease-in-out;

    .statusBar {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 6px;
    }

    .content {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      font-family: $font-bold;
      color: transparent;
      font-size: 10px;
    }

    .delete {
      visibility: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 30px;
      cursor: pointer;
    }
  }

  &:hover {
    .card {
      visibility: visible;
      opacity: 1;
      border-width: 1px;
      border-color: $color-alto;
      box-shadow: none;
      cursor: pointer;

      .content {
        color: $color-alto;
      }
    }
  }

  &.selected {
    .card {
      visibility: visible;
      opacity: 1;
      background: $color-white;
      border-width: 0;
      border-color: transparent;
      box-shadow: 0 2px 8px rgba($color-dark-gray, 0.1);

      .statusBar {
        background: $color-lidbot-green;
      }

      .content {
        color: $color-dark-gray;
      }

      &:hover {
        border-color: transparent;

      }
    }

    &:hover {
      .card {
        .delete {
          visibility: visible;
        }
      }
    }
  }

}