@import '../../../assets/scss/general';

.ApiKeys {

    .header {
        margin-bottom: 20px;
    }

    .row {
        min-height: 40px;
        margin-bottom: 8px;

        &:hover {
            .apiKeyCell .copyAPIButton {
                visibility: visible;
            }
        }
    }

    .highlightGroup {
        padding-left: 28px;
        margin-left: -28px;
    }

    .nameCell {
        font-family: Roboto-Bold;
        padding-left: 0;
        flex-basis: 40%;
    }

    .createdCell {
        flex-basis: 135px;
    }

    .lastUsedCell {
        flex-basis: 135px;
    }

    .apiKeyCell {
        flex-basis: 60%;
        display: flex;
        padding-right: 0;

        .apiKeyLabel {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            box-sizing: border-box;
        }

        .copyAPIButton {
            display: flex;
            flex: 0 0 60px;
            justify-content: center;
            align-items: center;
            font-family: Roboto-Bold;
            color: $color-lidbot-green;
            visibility: hidden;

            span {
                padding: 5px 10px;
                border-radius: 10px;
                transition: background-color 300ms;
                text-transform: capitalize;
            }

            &:hover {
                span {
                    cursor: pointer;
                }
            }
        }
    }

    .optionsCell {
        flex-basis: 52px;
    }

    .apiKeyStatusDisplay {

        .apiKeyInput {
            position: relative;
            width: 100%;
            min-height: 110px;
            background: $color-wild-sand;
            padding: 12px 24px 40px 24px;
            border-radius: 8px;
            outline: 0;
            word-break: break-all;

            .copyAPIButton {
                position: absolute;
                right: 0;
                bottom: 0;
                padding: 12px 20px;
                font-family: Roboto-Bold;
                color: $color-lidbot-green;
                display: flex;
                justify-content: flex-end;
                cursor: pointer;
            }
        }

        .apiRequestState {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px 24px;
            width: 100%;
            min-height: 50px;
            border: 1px solid $color-alto;
            border-radius: 8px;
        }
    }

    .warningMessage {
        margin-top: 15px;
        font-size: 14px;
        color: $color-dark-gray;
    }

    .buttonContainer {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;

        > button {
            flex: 0 0 calc(100% / 2 - 10px);
        }
    }
}