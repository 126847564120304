@import "../../../assets/scss/general";

.ScheduleSelector {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;

  .header {
    border-bottom: 1px solid rgba($color-alto, 0.75);
    .day {
      flex-basis: calc(100% / 7);
      min-width: 50px;
      font-family: $font-medium;
      font-size: 14px;
    }
  }

  .contentWrapper {
    overflow-y: scroll;

    .content {
      flex: 1;
      display: flex;

      .timeline {
        display: flex;
        flex-direction: column;
        font-family: $font-regular;
        font-size: 10px;
        line-height: 12px;
        color: $color-dark-gray;

        .time {
          transform: translateY(-6px);
        }
      }

      .day {
        flex-basis: calc(100% / 7);
      }
    }
  }

  &.readonly .content {
    pointer-events: none;
  }

  &.error {
    border: 1px solid rgba($color-apple-blossom, 0.75);
    border-radius: 10px;

    .header {
      //border-bottom: 1px solid rgba($color-apple-blossom, 0.75);
    }
  }
}