@import '../../../assets/scss/general';

$toggle-right-transition: left 417ms cubic-bezier(0.43, 0.0, 0.04, 1.0);
$toggle-left-transition: left 417ms cubic-bezier(0.53, 0.0, 0.09, 1.0);
$toggle-background-transition: background 200ms linear;
$toggle-right-gradient-background-transition: opacity 250ms linear 100ms;
$toggle-left-gradient-background-transition: opacity 267ms linear 67ms;

.Switch {
  position: relative;

  input {
    position: absolute;
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
    visibility: hidden;
  }

  .background {
    cursor: pointer;
    position: relative;
    background: $color-wild-sand;
    width: 52px;
    height: 26px;
    border-radius: 13px;
    overflow: hidden;

    .gradientBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(90deg, $color-lidbot-green 0%, rgba(0, 115, 85, 0.7) 100%);
      opacity: 0;
      transition: $toggle-left-gradient-background-transition;
    }

    .toggle {
      position: absolute;
      width: 26px;
      height: 26px;
      top: 0;
      left: 0;
      border-radius: 50%;
      transform: scale(0.8);
      background: $color-mine-shaft;
      transition: $toggle-left-transition, $toggle-background-transition;
    }
  }

  .help {
    margin-left: 5px;
  }

  &.checked {
    .background {
      .gradientBackground {
        opacity: 1;
        transition: $toggle-right-gradient-background-transition;
      }

      .toggle {
        left: 26px;
        background: $color-white;
        transition: $toggle-right-transition, $toggle-background-transition;
      }
    }
  }

  &.disabled {
    pointer-events: none;

    .background {
      cursor: not-allowed;

      .toggle {
        background: $color-silver-chalice;
      }
    }
  }

}