@import './assets/scss/fonts/fonts';
@import './assets/scss/general';
@import '~react-toastify/dist/ReactToastify.css';

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: border-box;
}


body {
  margin: 0;
  font-family: $font-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

text {
  &.recharts-text {
    &.recharts-cartesian-axis-tick-value {
      font-family: $font-medium;
      font-size: 13px;
    }
  }
}

em {
  color: $color-caribbean-green;
  font-style: normal;
}


strong {
  color: $color-black;
  font-family: $font-black;
}

button {
  &:hover, &:focus, &:active {
    outline: none;
  }
}

a {
  color: $color-silver-chalice;
  text-decoration: none;

  &:hover {
    color: $color-dark-gray;
  }
}

.Toastify {
  &__toast {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(51, 51, 51, 0.15);
    background: none;
    margin-bottom: 6px;
  }
}
