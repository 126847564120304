@import '../../../assets/scss/general';

.Flyover {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $color-gray-99;
  box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
  border-radius: 8px;
  width: 112px;
  overflow: hidden;

  .option {
    cursor: pointer;

    margin-top: -6px;
    &:first-of-type {
      margin-top: 0;
    }

    > div {
      font-family: $font-medium;
      font-size: 12px;
      padding: 12px;
      color: $color-black;
      text-transform: capitalize;
    }

    &.inactive {
      cursor: not-allowed;
      > div {
        color: rgba($color-dark-gray, 0.5);
        pointer-events: none;
      }
    }

    &:hover {
      background: $color-wild-sand;
    }
  }

  .divider {
    margin: 0;
    height: 1px;
    background: linear-gradient(to right, rgba($color-alto, 0.2) 0%, $color-alto 53.7%, rgba($color-alto, 0.2) 100%);
  }

  .destructiveOption {
    font-family: $font-medium;
    font-size: 12px;
    letter-spacing: 0.05em;
    padding: 12px 12px;
    margin: 0 0;
    color: $color-roman;
    cursor: pointer;
    text-transform: capitalize;

    &:hover {
      color: white;
      background: $color-roman;
    }
  }
}