.lidbot-bin-marker {
    display: flex;
    align-items: center;
    justify-content: center;
    background: red;
    background-size: cover;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    background: radial-gradient(circle at center, rgba(0, 255, 188, 0.36) 0%, transparent 65%, transparent 100%);
}

.lidbot-bin-marker .dot {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #46B490;
}

.lidbot-bin-marker.hasAlert {
    background: radial-gradient(circle at center, rgba(255, 45, 45, 0.36) 0%, transparent 65%, transparent 100%);
}

.lidbot-bin-marker.hasAlert .dot {
    background: #A74040;
}

.mapboxgl-popup.lidbot-bin-popup {
    width: 178px;
    min-height: 64px;
    cursor: pointer;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content {
    padding: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 2px 2px 15px 15px rgba(0, 0, 0, 0.1);
    transform: scale(1.0);
    transition: transform 300ms;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content:hover {
    transform: scale(1.05);
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content .LidbotPopup {
    display: flex;
    width: 100%;
    height: 100%;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content .LidbotPopup .statusBar {
    background: linear-gradient(180deg, #00C391 0%, rgba(23, 202, 155, 0.3) 100%);
    width: 6px;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content .LidbotPopup .fillPercentage {
    width: 52px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Roboto-Bold', "Arial Black", sans-serif;
    font-size: 16px;
    color: #333333;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content .LidbotPopup .info {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 11px 11px 11px 0;
}

.mapboxgl-popup.lidbot-bin-popup .mapboxgl-popup-content .LidbotPopup .info .title {
    display: flex;
    font-family: Roboto-Bold, "Arial Black", sans-serif;
    font-size: 13px;
    text-transform: uppercase;
    color: #757575;
    width: 109px;
    line-height: normal;
}

.mapboxgl-popup.lidbot-bin-popup.hasAlert .mapboxgl-popup-content .LidbotPopup .statusBar {
    background: linear-gradient(180deg, #A74040 0%, rgba(218, 100, 100, 0.5) 100%);
}

.mapboxgl-popup.lidbot-bin-popup.hasAlert .mapboxgl-popup-content .LidbotPopup .fillPercentage {
    color: #DA6464;
}