@import '../../../assets/scss/general';

.SensorList {
    .tiles {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(214px, 214px));
        grid-column-gap: 28px;
        grid-auto-rows: 1fr;
        grid-row-gap: 29px;
        margin: 0 -12px;
    }

    .list {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
    }
}
