@import '../../../assets/scss/general';

// Shared properties between header and row
.SensorRow {
    .iconCell {
        flex-basis: 30px;
    }

    .fillPctCell {
        flex-basis: 80px;
        padding-left: 10px;
    }

    .nextReportCell {
        flex-basis: 155px;
    }

    .typeCell {
        flex: 1 1 40%;
    }

    .nameCell {
        flex: 1 1 60%;
    }

    .locationCell {
        flex-basis: 200px;
    }

    .lastReportCell {
        flex-basis: 155px;
        justify-content: flex-end;
    }

    .batteryCell {
        flex-basis: 130px;
        display: flex;
        justify-content: space-between;
    }

    .optionsCell {
        flex-basis: 48px;
    }
}

.SensorRow {
    &.header {
        margin-bottom: 20px;
        margin-left: -10px;
    }
}

.SensorRow {
    &.row {
        height: 40px;
        margin-bottom: 8px;
        margin-left: -10px;

        .iconCell {
            .dot {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: $color-lidbot-green;
            }
        }

        .highlightGroup {
            margin-left: -2px;
            padding-left: 2px;
        }

        .strip {
            position: absolute;
            left: 0;
            width: 4px;
            height: 24px;
            border-radius: 2px;
            transition: height 300ms linear;
        }

        .fillPctCell {
            font-family: $font-bold;
            font-size: 16px;
            color: #010101;
        }

        .nextReportCell {
            font-family: $font-medium;
            font-size: 14px;
        }

        .typeCell {
            font-family: $font-medium;
            text-transform: uppercase;
            color: $color-caribbean-green;
        }

        .nameCell {
            font-family: $font-bold;
            text-transform: uppercase;
        }

        .locationCell {
            display: flex;

            .locationWrapper {
                display: flex;
                justify-content: flex-start;
                align-items: center;

                .locationIcon {
                    margin-right: 12px;
                }

                .locationData {
                    display: flex;
                    flex-direction: column;
                    font-size: 12px;
                    justify-content: center;
                    align-items: flex-start;
                }
            }
        }

        .optionsCell svg {
            fill: $color-alto;

            &:hover {
                fill: $color-dark-gray;
            }
        }

        &.isPinned .optionsCell > div {
            visibility: visible;

            svg {
                fill: $color-dark-gray;
            }
        }

        &.notResponding {
            .highlightGroup {
                //border-color: $color-apple-blossom;
                //border-width: 2px;
                //border-style: dashed;
                //border-radius: 12px;
            }
        }

        &.alert {
            .iconCell .dot {
                background: $color-apple-blossom;
            }
            &:hover {
                .strip {
                    height: 40px;
                }
            }

            .strip {
                background: linear-gradient(180deg, rgba($color-apple-blossom, 1.0) 0%, rgba($color-apple-blossom, 0.5) 100%);
            }

            .fillPctCell {
                color: $color-apple-blossom;
            }
        }
    }
}
