@import '../../../assets/scss/general';

.FilterControls {
    position: relative;

    .backdrop {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($color-white, 0.5);
        z-index: 1;
    }

    .controls {
        position: relative;
        z-index: 2;

        ul {
            display: flex;
            margin: 0;
            padding: 0;

            > li {
                list-style: none;
                margin: 0 0 0 12px;
                font-family: $font-regular;
                font-size: 14px;
                line-height: 16px;
                color: $color-boulder;
                cursor: pointer;
                white-space: nowrap;

                &:before {
                    display: block;
                    content: attr(title);
                    font-family: $font-bold;
                    height: 0;
                    overflow: hidden;
                    visibility: hidden;
                }

                em {
                    font-family: $font-bold;
                    opacity: 0;
                }

                .arrow {
                    visibility: hidden;
                    margin-left: 4px;
                    margin-bottom: 2px;
                    transform: scaleY(1);
                    transition: transform 300ms linear;

                    &.desc {
                        transform: scaleY(-1);
                    }
                }

                &.active {
                    font-family: $font-bold;
                    color: $color-dark-gray;

                    em {
                        opacity: 1;
                    }

                    .arrow {
                        visibility: visible;
                    }
                }
            }
        }

        .customDurationLabelWrapper {
            display: flex;
            visibility: hidden;
            justify-content: flex-end;
            position: relative;
            margin-top: 6px;
            margin-right: -10px;

            .customDurationIcon {
                font-family: $font-bold;
                font-size: 22px;
                margin-right: 0.5em;
                color: $color-dark-gray;
            }

            .customDurationLabel {
                position: relative;
                display: flex;
                font-family: $font-bold;
                font-size: 14px;
                letter-spacing: 0.05em;
                align-items: center;
                height: 38px;
                padding: 0 8px;
                border-radius: 8px;
                color: $color-dark-gray;

                .customDurationText {
                    position: relative;

                    input {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        font-family: $font-bold;
                        font-size: 14px;
                        letter-spacing: 0.05em;
                        -webkit-appearance: none;
                        appearance: none;
                        outline: none;
                        border: none;
                        padding: 0;
                    }

                    .customLabels {
                        visibility: hidden;
                    }
                }

                .customDurationIcon {
                    width: 18px;
                    height: 18px;
                    border-radius: 9px;
                    background: #CFCFCF;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: 10px;
                    transition: all 300ms;

                    svg {
                        fill: $color-white;
                        width: 8px;
                        height: 8px;
                    }
                }

                &:hover {
                    background: $color-gray-99;
                    box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);

                    .customDurationIcon {
                        background: $color-caribbean-green;
                    }
                }
            }

            .errorMsg {
                color: $color-apple-blossom;
                font-size: 10px;
                font-family: $font-regular;
                margin-right: 10px;
                display: flex;
                align-items: center;
                visibility: hidden;
            }

            &.visible {
                visibility: visible;
            }

            &.editing {
                .customDurationIcon {
                    width: 0;
                    margin-right: 0;
                    background: transparent;
                }

                .customDurationLabel {
                    background: $color-gray-99;
                    box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
                }

                &.invalid {
                    .errorMsg {
                        visibility: visible;
                    }
                    .customDurationLabel .customDurationText input {
                            color: $color-apple-blossom;
                    }
                }
            }
        }

        .customDurationPicker {
            position: absolute;
            right: -10px;
            margin-top: 16px;
            padding: 40px;
            width: 626px;
            background: $color-gray-99;
            box-shadow: 0 4px 8px rgba($color-dark-gray, 0.15);
            border-radius: 8px;
            z-index: 3;

            .triangle {
                position: absolute;
                right: 25px;
                top: -16px;
            }

            .calendarContainer {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: stretch;
                margin-bottom: 30px;

                .separator {
                    position: relative;
                    flex: 0 0 1px;
                    background: linear-gradient(180deg, rgba($color-alto, 0.2) 0%, $color-alto 53.78%, rgba($color-alto, 0.2) 100%);
                }
            }

            .durationPickerControls {
                display: flex;

                .durationStatus {
                    flex: 1 1 0;
                    display: flex;
                    align-items: center;

                    .iconWrapper {
                        width: 18px;
                        height: 18px;
                    }

                    .statusIcon {
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: transparent;
                        transition: background 300ms;
                        background: $color-active-green;

                        svg {
                            fill: $color-white;
                            width: 8px;
                            height: 8px;
                        }
                    }
                    .statusLabel {
                        margin-left: 10px;
                        font-family: $font-regular;
                        color: $color-dark-gray;
                        transition: color 300ms, opacity 300ms;
                        font-size: 12px;
                        opacity: 0.5;

                        &.active {
                            color: $color-active-green;
                            font-family: $font-bold;
                            opacity: 1;
                        }
                    }

                    .dash {
                        margin: 0 18px;
                        font-family: $font-medium;
                        font-size: 12px;
                        color: #cfcfcf;
                    }
                }
            }
        }
    }
}
