@import '../../../assets/scss/general';

.WasteCollectionPanel {
  padding: 25px 0 0 0;

  .chartTitle {
    display: flex;
    align-items: center;
    font-family: $font-bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.02em;
    color: #858585;

    span {
      margin-right: 5px;
    }
  }

  .chart {
    position: relative;

    .statusBar {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      position: absolute;
      top: 0;
      left: -15px;
      width: 7px;
      height: 100%;
    }

    .content {
      .wasteLabel {
        font-family: $font-bold;
        font-size: 13px;
        text-transform: capitalize;
      }
    }
  }

  .tooltip {
    display: flex;
    align-items: stretch;
    background: $color-white;
    color: $color-dark-gray;
    border-radius: 8px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 20px;

    &:after {
      top: 100%;
      left: 50%;
      content: ' ';
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border: 8px solid rgba(255, 255, 255, 0);
      border-top-color: #fff;
      margin-left: -8px;
    }

    .statusBar {
      flex: 0 0 4px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: linear-gradient(135deg, rgba(0, 195, 145, 1) 0%, rgba(23, 202, 155, 0.3) 100%);
    }
  }
}
