@import '../../../assets/scss/general';

.DashboardPage {
    .panels {
        > div {
            flex-direction: column;

            @include breakpoint(small) {
                flex-direction: row;
                flex-wrap: wrap;
                flex-basis: calc(50% - (1 / 2) * 25px);
                margin: 0 0 25px 25px;

                &:nth-child(1),
                &:nth-child(3) {
                    margin: 0 0 25px 0;
                }
            }

            @include breakpoint(large) {
                flex-basis: calc(25% - (3 / 4) * 25px);
                margin: 0 0 25px 25px;

                &:nth-child(1) {
                    margin: 0 0 25px 0;
                }

                &:nth-child(3) {
                    margin-left: 25px;
                }
            }
        }
    }
}
