@use '../../../assets/scss/general' as *;

.SensorDetailsHeader {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 40px;

    .sensorDetails {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    .sensorStateDetails {
        font-family: Roboto-Black;
        font-size: 14px;
        font-style: normal;
        line-height: 12px;
        letter-spacing: 0;
        margin-top: 8px;

        svg {
            vertical-align: bottom;
            margin-left: 9px;
            margin-right: 20px;
            width: 22px;
            height: 14px;
        }
    }

    .sensorData {
        display: flex;
        align-items: center;

        .sensorFillLevel {
            flex: 0 1 auto;
            position: relative;
            font-family: $font-medium;
            font-size: 60px;
            line-height: 60px;
            color: $color-dark-gray;
            padding-right: 20px;
            margin-right: 20px;

            .sensorFillLevelUnit {
                position: absolute;
                top: 0;
                right: 0;
                font-family: $font-medium;
                font-size: 30px;
                line-height: 35px;
            }
        }

        .sensorIdentity {
            display: flex;
            flex-direction: column;

            .sensorName {
                flex: 1 1 auto;
                font-family: $font-black;
                font-size: 26px;
                line-height: 30px;
                color: $color-dark-gray;
                text-transform: uppercase;
            }

            .sensorType {
                flex: 1 1 auto;
                display: flex;
                align-items: center;
                height: 18px;

                &:before {
                    //content: '';
                    //display: block;
                    //width: 6px;
                    //height: 6px;
                    //margin-right: 8px;
                    //border-radius: 8px;
                    //background-color: $color-caribbean-green;
                }

                .sensorTypeName {
                    font-family: $font-medium;
                    font-size: 18px;
                    line-height: 21px;
                    color: $color-caribbean-green;
                    text-transform: uppercase;
                }
            }
        }
    }

    &.isConnected .sensorData .sensorIdentity .sensorType:before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        margin-right: 8px;
        border-radius: 8px;
        background-color: $color-caribbean-green;
    }

    &.hasAlert {
        .sensorFillLevel,
        .sensorTypeName {
            color: $color-roman !important;
        }

        .sensorType {
            &:before {
                background-color: $color-roman !important;
            }
        }
    }

    .staffLabel {
        padding-right: 10px;
    }
}

$restaurant-logo-size: 49px;

.sensorLogo {
    height: 49px;
    width: 49px;
    object-fit: contain;
    border-radius: 100%;
    margin: 0 0 0 20px;
    padding: 2px;
    border: 1px solid #e5e5e5b1;
}
