@import '../../../assets/scss/general';

.DashboardStats {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .statsColumn {
        display: flex;
        align-items: center;

        + .statsColumn {
            margin-left: 40px;
        }

        &.green {
            color: $color-caribbean-green;
        }

        &.red {
            color: $color-apple-blossom;
        }

        &.gray {
            color: $color-alto;
        }

        .statsValue {
            font-family: $font-black;
            font-size: 36px;
            line-height: 42px;
            margin-right: 15px;
        }

        .statsLabel {
            font-family: $font-medium;
            font-size: 13px;
            line-height: 16px;
        }
    }

    .datePicker {
        flex: 1;
        flex-wrap: wrap;
        display: flex;
        justify-content: flex-end;
    }
}
